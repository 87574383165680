import { useContext } from "react";
import { AppBarStateContext } from "../components/AppBarProvider/context";

export const useAppBar = (): {
  show: () => void;
  hide: () => void;
  setTitle: (title: string) => void;
} => {
  const context = useContext(AppBarStateContext);

  return {
    show: context?.show
      ? context.show
      : () => {
          throw new Error("Not implmeented show method");
        },
    hide: context?.hide
      ? context.hide
      : () => {
          throw new Error("Not implemented hide method");
        },
    setTitle: context?.setTitle
      ? context.setTitle
      : () => {
          throw new Error("Not implemented set title");
        },
  };
};
