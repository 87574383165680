import { useCallback, useEffect, useState } from "react";
import { ref, onValue, update } from "firebase/database";
import { auth, db } from "../config/firebase";
import { MealType, Users } from "../types/userTypes";
import { validate as isUuid } from "uuid";
import { useSnackbar } from "./useSnackbar";
import { signInAnonymously, updateProfile } from "firebase/auth";

const isEmpty = (obj: any) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
};

export const useUsers = (
  invitationPath: string
): {
  users: Users;
  loading: boolean;
  updateMealType: (userId: string, mealType: MealType) => Promise<void>;
  updateParticipate: (userId: string, participate: boolean) => Promise<void>;
} => {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { show: showMessage } = useSnackbar();

  const invitationId = invitationPath.split("/").pop();

  useEffect(() => {
    const loadData = () => {
      const userDataRef = ref(db, invitationPath);
      if (
        invitationId !== "null" &&
        invitationId !== "undefined" &&
        invitationId &&
        isUuid(invitationId)
      ) {
        onValue(userDataRef, (snapshot) => {
          if (!snapshot.val()) {
            setData(undefined);
          } else {
            let result = { [invitationId]: {} };
            snapshot.forEach((element) => {
              if (element.key) {
                result = {
                  ...result,
                  [invitationId]: {
                    ...result[invitationId],
                    [element.key]: { ...element.val() },
                  },
                };
              }
            });
            if (isEmpty(result[invitationId])) {
              setData(undefined);
            } else {
              setData(result);
            }
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    };


    if (!auth.currentUser) {
      signInAnonymously(auth).then((userCredentials) => {
        updateProfile(userCredentials.user, {
          displayName: invitationId
        }).then(loadData)
      })
    } else {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationPath]);

  const updateMealType = useCallback(
    (userId: string, mealType: MealType) => {
      if (invitationId && isUuid(invitationId) && isUuid(userId)) {
        const userData = data[invitationId][userId];
        return update(ref(db), {
          ["users/" + invitationId + "/" + userId + ""]: {
            ...userData,
            mealType,
          },
        }).then(() => {
          showMessage("Deine Menüauswahl wurde gespeichert!", "success");
        });
      }
      return Promise.reject(
        `Invalid data. Either invititationId '${invitationId}' or userId '${userId}' is invalid.`
      );
    },
    [data, invitationId, showMessage]
  );

  const updateParticipate = useCallback(
    (userId, participate) => {
      if (invitationId && isUuid(invitationId) && userId && isUuid(userId)) {
        const userData = data[invitationId][userId];
        return update(ref(db), {
          ["users/" + invitationId + "/" + userId + ""]: {
            ...userData,
            participate: participate === "true" || participate === true,
          },
        }).then(() => {
          showMessage("Deine Teilnahme wurde gespeichert!", "success");
        });
      }
      return Promise.reject(
        `Invalid data. Either invititationId '${invitationId}' or userId '${userId}' is invalid.`
      );
    },
    [data, invitationId, showMessage]
  );

  return {
    users: data,
    loading,
    updateMealType,
    updateParticipate,
  };
};
