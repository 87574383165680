import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { isMealType, MealType, User } from "../../types/userTypes";
import { getBoolean } from "../../util/booleanUtils";
import "./index.css";

const ParticipateRadioGroup = ({
  name,
  user,
  onChange,
}: {
  name: string;
  user: User;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}) => {
  return (
    <FormControl>
      <FormLabel
        id="participate-group-label"
        sx={(theme) => ({
          padding: theme.spacing(1),
        })}
      >
        Wirst Du teilnehmen?
      </FormLabel>
      <RadioGroup
        aria-labelledby="participate-group-label"
        value={user.participate}
        name={name}
        onChange={onChange}
        sx={(theme) => ({
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        })}
      >
        <FormControlLabel value={true} control={<Radio />} label="Ja, gerne" />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="Leider nicht"
          defaultChecked={!user.participate}
        />
      </RadioGroup>
    </FormControl>
  );
};

const MenuRadioGroup = ({
  name,
  user,
  onChange,
}: {
  name: string;
  user: User;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}) => {
  return (
    <FormControl>
      <FormLabel
        id="menu-group-label"
        sx={(theme) => ({
          padding: theme.spacing(1),
        })}
      >
        Wähle Deine Menüvariante:
      </FormLabel>
      <RadioGroup
        aria-labelledby="menu-group-label"
        name={name}
        value={user.mealType}
        onChange={onChange}
        sx={(theme) => ({
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        })}
      >
        <FormControlLabel
          value="VEGETARIAN"
          control={<Radio />}
          label="Vegetarisch"
        />
        <FormControlLabel
          value="MEAT"
          control={<Radio />}
          label="Mit Fisch und Fleisch"
        />
      </RadioGroup>
    </FormControl>
  );
};

interface Props {
  user: User;
  userId: string;
  onMealTypeChanged: (userId: string, mealType: MealType) => Promise<void>;
  onParticipateChanged: (userId: string, participate: boolean) => Promise<void>;
}

export const SelectionCard = ({
  user,
  userId,
  onMealTypeChanged,
  onParticipateChanged,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const participateRadioGroupName = `participate-group-${userId}`;
  const menuRadioGroupName = `menu-group-${userId}`;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    event.preventDefault();
    setLoading(true);
    const groupName = event.target.name;
    const userId = groupName.slice(-36);
    if (groupName === participateRadioGroupName) {
      const boolValue = getBoolean(value);
      onParticipateChanged(userId, boolValue)
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    }
    if (groupName === menuRadioGroupName && isMealType(value)) {
      onMealTypeChanged(userId, value)
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    }
    // }
  };

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "#FFFFFF",
      }}
    >
      {loading ? (
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, .9)",
            zIndex: 100000,
            borderRadius: theme.shape.borderRadius,
          })}
        >
          <CircularProgress />
        </Box>
      ) : undefined}
      <Box
        sx={(theme) => ({
          padding: theme.spacing(1),
          color: theme.palette.text.secondary,
          borderBottom: 1,
          borderBottomColor: "grey.200",
        })}
      >
        <Typography variant="h5">
          <span className="card-title">{`${user.firstName} ${user.lastName}`}</span>
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(1),
          borderBottom: 1,
          borderBottomColor: "grey.200",
        })}
      >
        <ParticipateRadioGroup
          name={participateRadioGroupName}
          user={user}
          onChange={handleChange}
        />
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(1),
        })}
      >
        <MenuRadioGroup
          name={menuRadioGroupName}
          user={user}
          onChange={handleChange}
        />
      </Box>
    </Paper>
  );
};
