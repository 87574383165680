import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { validate as isUuid } from "uuid";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { signInAnonymously, updateProfile } from "firebase/auth";
import { auth } from "../config/firebase";
import { Loading } from "./Loading";
import { useSnackbar } from "../hooks/useSnackbar";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const Login = ({}: Props) => {
  const [searchParams] = useSearchParams();
  const invitationId: string | null = searchParams.get("invitationId");
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const { show: showMessage } = useSnackbar();

  useEffect(() => {
    const nav = (uuid: string) => {
      navigate(`/?invitationId=${uuid}`);
    };

    return auth.onAuthStateChanged((user) => {
      if (user) {
        if (
          (!user.displayName || !isUuid(user.displayName)) &&
          invitationId &&
          isUuid(invitationId)
        ) {
          updateProfile(user, {
            displayName: invitationId,
          }).then(() => {
            nav(invitationId);
          });
        } else {
          if (auth.currentUser?.displayName) {
            nav(auth.currentUser?.displayName);
          }
        }
      }
      setLoading(false);
      if (state?.error) {
        showMessage(state?.error, "error");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: FieldValues) => {
    setLoading(true);

    const nav = (uuid: string) => {
      navigate(`/?invitationId=${uuid}`);
      setLoading(false);
    };

    const uuid = data.password;
    if (uuid && isUuid(uuid.toString())) {
      if (!auth.currentUser) {
        signInAnonymously(auth).then((userCredential) => {
          updateProfile(userCredential.user, {
            displayName: uuid,
          }).then(() => {
            nav(uuid);
          });
        });
      } else {
        if (
          (!auth.currentUser.displayName ||
            !isUuid(auth.currentUser.displayName)) &&
          uuid &&
          isUuid(uuid)
        ) {
          updateProfile(auth.currentUser, {
            displayName: uuid,
          }).then(() => {
            nav(uuid);
          });
        } else {
          nav(uuid);
        }
      }
    } else {
      setLoading(false)
      showMessage("Passwort ungültig", "error");
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          textAlign: "center",
          width: 350,
          p: theme.spacing(2),
          pb: theme.spacing(0),
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Typography component="h1" variant="h5">
          Anmelden
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={(theme) => ({ mt: 1, padding: theme.spacing(2) })}
        >
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                required
                label="Passwort"
                type="text"
                autoComplete="current-password"
                fullWidth
              />
            )}
            control={control}
            name="password"
            defaultValue={invitationId ?? ""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Anmelden
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Login;
