import { ReactNode, useState } from "react";
import {
  AdminAuthenticationState,
  AdminAuthenticationStateContext,
} from "./contex";

interface Props {
  children: ReactNode;
}

export const AdminAuthProvider = ({ children }: Props) => {
  const [state, setState] = useState<AdminAuthenticationState | undefined>(
    undefined
  );

  return (
    <AdminAuthenticationStateContext.Provider
      value={{
        authenticatedUser: state?.authenticatedUser,
        authenticatedAt: state?.authenticatedAt,
        invitations: state?.invitations,
        setAuthenticationState: setState,
      }}
    >
      {children}
    </AdminAuthenticationStateContext.Provider>
  );
};
