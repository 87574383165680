import { Box, Typography } from "@mui/material";
import { UserDataRow } from "./types";

interface Props {
  rows: UserDataRow[];
}

export const Statistics = ({ rows }: Props) => {
  const numberOfInvitees = rows.reduce(
    (accumulator, row) => accumulator + row.guests.length,
    0
  );
  const numberOfGuests = rows.reduce(
    (accumulator, row) =>
      accumulator + row.guests.filter((guest) => guest.participate).length,
    0
  );
  const numberOfVegetarians = rows.reduce(
    (accumulator, row) =>
      accumulator +
      row.guests.filter((guest) => guest.mealType === "VEGETARIAN").length,
    0
  );
  const toPercentage = (value: number, count: number) =>
    `${Math.round((value / count) * 100)}%`;
  return (
    <Box
      sx={(theme) => ({
        display: "inline-flex",
        flexWrap: "wrap",
        gap: "12px",
        width: "100%",
        textAlign: "center",
      })}
    >
      <Typography
        variant="overline"
        sx={(theme) => ({ marginBottom: theme.spacing(2), flexGrow: "1" })}
      >
        Eingeladungen: {rows.length}
      </Typography>
      <Typography
        variant="overline"
        sx={(theme) => ({ marginBottom: theme.spacing(2), flexGrow: "1" })}
      >
        Eingeladene Gäste: {numberOfInvitees}
      </Typography>
      <Typography
        variant="overline"
        sx={(theme) => ({ marginBottom: theme.spacing(2), flexGrow: "1" })}
      >
        Teilnehmende Gäste: {numberOfGuests}/{numberOfInvitees} (
        {toPercentage(numberOfGuests, numberOfInvitees)})
      </Typography>
      <Typography
        variant="overline"
        sx={(theme) => ({ marginBottom: theme.spacing(4), flexGrow: "1" })}
      >
        Vegetarier: {numberOfVegetarians}/{numberOfGuests} (
        {toPercentage(numberOfVegetarians, numberOfGuests)})
      </Typography>
    </Box>
  );
};
