import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { TablePaginationActions } from "./TablePaginationActions";
import { UserDataRow } from "./types";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

interface Props {
  rows: UserDataRow[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  center: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    center: false,
  },
  {
    id: "vegetarian",
    numeric: false,
    disablePadding: false,
    label: "Vegetarisch",
    center: true,
  },
  {
    id: "meat",
    numeric: false,
    disablePadding: false,
    label: "Inklusive Fisch & Fleisch",
    center: true,
  },
  {
    id: "participate",
    numeric: false,
    disablePadding: false,
    label: "Teilnahme",
    center: true,
  },
];

type Order = "asc" | "desc";

interface Data {
  id: string;
  name: string | undefined;
  vegetarian: number;
  meat: number;
  participate: number;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.center ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={orderBy === headCell.id}
              sx={{
                "&.MuiTableSortLabel-root": {
                  color: "white",
                },
                "&.MuiTableSortLabel-root:hover": {
                  color: "#CCC",
                },
                "&.Mui-active": {
                  color: "#CCC",
                },
                "& .MuiTableSortLabel-icon": {
                  color: "#CCC !important",
                },
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const GuestOverview = ({ rows: invitations }: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const [originalRows] = useState(
    invitations
      .flatMap((invitation) => invitation.guests)
      .map((guest) => ({
        id: guest.userId,
        name: `${guest.firstName} ${guest.lastName}`,
        participate: guest.participate ? 1 : 0,
        vegetarian: guest.mealType === "VEGETARIAN" ? 1 : 0,
        meat: guest.mealType === "MEAT" ? 1 : 0,
      }))
  );
  const [rows, setRows] = useState(originalRows);

  useEffect(() => {
    setRows(stableSort(rows, getComparator(order, orderBy)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setPage, setRowsPerPage]
  );

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof Data) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const requestSearch = useCallback(
    (searched: string) => {
      setRows(
        stableSort(
          originalRows.filter((item) =>
            item.name.toLowerCase().includes(searched)
          ),
          getComparator(order, orderBy)
        )
      );
      setPage(0);
    },
    [order, orderBy, originalRows]
  );

  return (
    <>
      <FormControl
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
          width: "100%",
        })}
        variant="outlined"
        onInput={(event) =>
          requestSearch((event.target as HTMLInputElement).value)
        }
      >
        <InputLabel htmlFor="outlined-adornment-password">Suchen</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type="search"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <ManageSearchIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Suchen"
        />
      </FormControl>
      <TableContainer>
        <Table aria-label="customized table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.name}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.vegetarian ? <CheckIcon /> : <CloseIcon />}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.meat ? <CheckIcon /> : <CloseIcon />}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.participate ? <CheckIcon /> : <CloseIcon />}
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={4} />
              </StyledTableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "Alle", value: -1 }]}
                colSpan={4}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Einladungen pro Seite",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}-${to} von ${count}`;
                }}
                labelRowsPerPage="Gäste pro Seite"
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
