import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../hooks/useAdmin";

interface Props {
  children: React.ReactNode;
}

const AdminAuthChecker = ({ children }: Props) => {
  const navigate = useNavigate();
  const { loggedIn, loading } = useAdmin();

  const conditionallyLeave = useCallback(() => {
    if (!loggedIn && !loading) {
      navigate("/admin-login");
    }
  }, [loading, loggedIn, navigate]);

  useEffect(() => {
    conditionallyLeave();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(conditionallyLeave, [conditionallyLeave]);

  return <>{children}</>;
};

export default AdminAuthChecker;
