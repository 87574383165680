import { onValue, ref } from "firebase/database";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  AdminAuthenticationStateContext,
  Credentials,
} from "../components/AdminAuthenticationProvider/contex";
import { db } from "../config/firebase";
import { Guest, Invitations, Users } from "../types/userTypes";

export const useAdmin = (): {
  authenticatedUser?: {
    [uuid: string]: Credentials;
  };
  loggedIn: boolean;
  invitations?: Invitations;
  logIn: (username: string, password: string) => Promise<void>;
  logOut: () => void;
  loading: boolean;
} => {
  const {
    authenticatedUser,
    authenticatedAt,
    setAuthenticationState,
    invitations,
  } = useContext(AdminAuthenticationStateContext) || {};
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (authenticatedAt && authenticatedUser && setAuthenticationState) {
      return onValue(ref(db, "users/"), (snapshot) => {
        const invitations: Invitations = {};
        const allUsers = snapshot.val() as Users;
        // let links = ""
        Object.entries(allUsers).forEach(([invitationId, users]) => {
          const guests: Guest[] = [];
          Object.entries(users).forEach(([userId, value]) => {
            guests.push({
              userId,
              ...value,
            });
            invitations[invitationId] = {
              guests,
            };
          });
          // links += `https://www.juliane-und-denis.de?invitationId=${invitationId}\n`
        });
        setAuthenticationState((previousState) => ({
          authenticatedUser: previousState?.authenticatedUser,
          authenticatedAt: previousState?.authenticatedAt,
          invitations,
          setAuthenticationState: previousState?.setAuthenticationState,
        }));
        // console.log(links)
      });
    } else {
      if (setAuthenticationState) {
        setAuthenticationState((previousState) => ({
          ...previousState,
          invitations: undefined,
        }));
      }
    }
  }, [authenticatedUser, authenticatedAt, setAuthenticationState]);

  const logIn = useCallback(
    (username: string, password: string): Promise<void> => {
      setLoading(true);
      return new Promise<void>((resolve, reject) => {
        onValue(
          ref(db, "admins/"),
          (snapshot) => {
            snapshot.forEach((user) => {
              if (
                user.val().username === username &&
                user.val().password === password &&
                user.key
              ) {
                if (setAuthenticationState) {
                  setAuthenticationState((previousState) => {
                    if (user.key) {
                      return {
                        ...previousState,
                        authenticatedUser: {
                          [user.key]: {
                            ...user.val(),
                          },
                        },
                        authenticatedAt: new Date(),
                      };
                    } else {
                      return previousState;
                    }
                  });
                } else {
                  setLoading(false);
                  reject(
                    "Es ist aufgrund technischer Probleme nicht möglich sich als Administrator anzumelden."
                  );
                }
              }
            });

            setLoading(false);
            if (authenticatedUser) {
              resolve();
            } else {
              reject("Benutzername oder Passwort stimmen nicht.");
            }
          },
          {
            onlyOnce: true,
          }
        );
      });
    },
    [authenticatedUser, setAuthenticationState]
  );

  const logOut = useCallback(() => {
    if (setAuthenticationState) {
      setAuthenticationState((previousState) => ({
        ...previousState,
        authenticatedUser: undefined,
        authenticatedAt: undefined,
      }));
    }
  }, [setAuthenticationState]);

  return {
    authenticatedUser,
    loggedIn: authenticatedUser !== undefined,
    logIn,
    logOut,
    loading,
    invitations,
  };
};
