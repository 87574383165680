import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SelectionCard } from "../components/SelectionCard";
import Logo from "../components/Logo";
import Center from "../components/utils/Center";
import { useUsers } from "../hooks/useUsers";

import "./Home.css";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { User } from "../types/userTypes";
import { auth } from "../config/firebase";

interface Props {}

const Home = ({}: Props) => {
  const [searchParams] = useSearchParams();
  const invitationId: string | null = searchParams.get("invitationId");
  const { users, loading, updateMealType, updateParticipate } = useUsers(
    `users/${invitationId}`
  );
  const navigate = useNavigate();
  const [guests, setGuests] = useState<{ userId: string; user: User }[]>([]);

  useEffect(() => {
    if (!users && !loading) {
      auth.signOut();
      navigate("/login");
    }

    if (users) {
      const newGuests: { userId: string; user: User }[] = [];
      if (invitationId) {
        Object.entries(users[invitationId]).forEach(([userId, user]) => {
          newGuests.push({ userId, user });
          newGuests.sort((left, right) => {
            return left.user.ordinal - right.user.ordinal;
          });
        });
      }
      setGuests(newGuests);
    }
  }, [invitationId, loading, navigate, users]);

  return loading ? (
    <Center>
      <CircularProgress />
    </Center>
  ) : (
    <>
      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          justifyContent: "center",
        })}
      >
        <Grid item xs={12} sm={10} md={6} lg={6}>
          <Logo />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          justifyContent: "center",
        })}
      >
        {guests.map((guest) => {
          return (
            <Grid item xs={12} sm={6} lg={3} xl={2} key={guest.userId}>
              <SelectionCard
                user={guest.user}
                userId={guest.userId}
                onMealTypeChanged={updateMealType}
                onParticipateChanged={updateParticipate}
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          justifyContent: "center",
          textAlign: "center",
          fontWeight: theme.typography.fontWeightLight,
        })}
      >
        <Grid item xs={12} sm={12} lg={6} xl={4}>
          <Typography
            variant="h4"
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <span className="main-headline">Zeitlicher Ablauf</span>
          </Typography>
          <Typography
            variant="h5"
            sx={(theme) => ({
              marginTop: theme.spacing(2),
            })}
          >
            <span className="sub-headline">Um 14:30</span>
          </Typography>
          <span>
            Die Trau­ung fin­det am Dock­land (Fi­sche­rei­ha­fen) auf dem
            Schiff "MS Ham­bur­ger De­ern" (Van-der-Smis­sen-Stra­ße 9, 22767
            Ham­burg) statt. Das Schiff muss zü­gig und pünkt­lich ab­le­gen,
            da­her seid bit­te pünkt­lich!
          </span>
          <Typography
            variant="h5"
            sx={(theme) => ({
              marginTop: theme.spacing(2),
            })}
          >
            <span className="sub-headline">Gegen 17:30</span>
          </Typography>
          <span>
            An­schlie­ßend fin­den die Fei­er­lich­kei­ten im Re­stau­rant "Am
            Kai" (Gro­ße Elb­stra­ße 145c, 22767 Ham­burg) statt.
          </span>
          <Typography
            variant="h4"
            sx={(theme) => ({
              marginTop: theme.spacing(3),
            })}
          >
            <span className="main-headline">Zusatzinformationen</span>
          </Typography>
          <Typography
            variant="h5"
            sx={(theme) => ({
              marginTop: theme.spacing(2),
            })}
          >
            <span className="sub-headline">Dresscode</span>
          </Typography>
          <span>
            "Black Tie" – An­zug und Abend­kleid, "weiß" bleibt der Braut
            vor­be­hal­ten.
          </span>
          <Typography
            variant="h5"
            sx={(theme) => ({
              marginTop: theme.spacing(2),
            })}
          >
            <span className="sub-headline">Geschenkideen</span>
          </Typography>
          <span>
            Am meis­ten wür­den wir uns über ei­nen fi­nan­zi­el­len Zu­schuss
            für den Start ins ge­mein­sa­me Ehe­le­ben freu­en.
          </span>
          <Box
            sx={(theme) => ({
              display: "flex",
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(4),
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <AddToCalendarButton
              name="Hochzeit von Juliane & Denis Lux"
              startDate="2023-08-26"
              startTime="14:30"
              endDate="2023-08-27"
              endTime="17:30"
              timeZone="Europe/Berlin"
              options={[
                "Apple",
                "Google",
                "iCal",
                "Microsoft365",
                "Outlook.com",
                "Yahoo",
              ]}
              location="Gro­ße Elb­stra­ße 145c 22767 Ham­burg"
              label="Zum Kalender hinzufügen"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
