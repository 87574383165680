import { useContext } from "react";
import {
  Severity,
  SnackbarContext,
} from "../components/SnackbarProvider/context";

export const useSnackbar = (): {
  show: (message: string, severity: Severity) => void;
} => {
  const context = useContext(SnackbarContext);

  return {
    show: context?.show
      ? context?.show
      : (_message: string) => {
          throw new Error("Not implemented");
        },
  };
};
