import AdminArea from "../screens/AdminArea";
import AdminLogin from "../screens/AdminLogin";
import Home from "../screens/Home";
import Login from "../screens/Login";

interface RouteType {
  path: string;
  component: any;
  name: string;
  adminProtected: boolean;
}

const routes: RouteType[] = [
  {
    path: "",
    component: Home,
    name: "Home Screen",
    adminProtected: false,
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    adminProtected: false,
  },
  {
    path: "/admin-login",
    component: AdminLogin,
    name: "Admin Login Screen",
    adminProtected: false,
  },
  {
    path: "/admin",
    component: AdminArea,
    name: "Admin Area",
    adminProtected: true,
  },
];

export default routes;
