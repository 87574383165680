import BackgroundSVG from "../../img/rings.svg";
import { Typography } from "@mui/material";
import "./index.css";

interface Props {}

const Logo = ({}: Props) => {
  return (
    <>
      <div className="rings-container">
        <img src={BackgroundSVG} alt="logo" />
        <span className={"rings-names"}>Juliane & Denis</span>
      </div>
      <Typography variant="h6">
        <span className="rings-date">Hochzeit am 26. August 2023</span>
      </Typography>
    </>
  );
};

export default Logo;
