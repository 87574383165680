import { createTheme, responsiveFontSizes } from "@mui/material";

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: "#FAFBFC",
      },
      primary: {
        main: '#9A753D'
      }
    },
    typography: {
      fontWeightRegular: 200,
    }
  })
);

export default theme;
