import { Container, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminAuthChecker from "./components/AdminAuthChecker";
import { AdminAuthProvider } from "./components/AdminAuthenticationProvider";
import { AppBarProvider } from "./components/AppBarProvider";
import { SnackbarProvider } from "./components/SnackbarProvider";
import routes from "./config/routes";
import theme from "./config/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AdminAuthProvider>
        <AppBarProvider>
          <SnackbarProvider>
            <Container component="main" maxWidth={false}>
              <CssBaseline />
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                  {routes.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        route.adminProtected ? (
                          <AdminAuthChecker>
                            <route.component />
                          </AdminAuthChecker>
                        ) : (
                          <route.component />
                        )
                      }
                    />
                  ))}
                  {/** This is needed to have a fallback route that is not a 404 component. For simplicity this will always lead the user to the home page */}
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </BrowserRouter>
            </Container>
          </SnackbarProvider>
        </AppBarProvider>
      </AdminAuthProvider>
    </ThemeProvider>
  );
}

export default App;
