import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

interface Props {}

export const Loading = ({}: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      })}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};
