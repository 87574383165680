import { createContext } from "react";

export type Severity = "error" | "warning" | "info" | "success"

export interface SnackbarActions {
    show: (message: string, severity: Severity) => void
}

export const SnackbarContext = createContext<SnackbarActions | undefined>(
  undefined
);
