import {
  Avatar,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAdmin } from "../hooks/useAdmin";
import { useAppBar } from "../hooks/useAppBar";
import { auth } from "../config/firebase";
import { useSnackbar } from "../hooks/useSnackbar";
import { Loading } from "./Loading";
import { signInAnonymously } from "firebase/auth";

interface Props {}

// eslint-disable-next-line no-empty-pattern
const AdminLogin = ({}: Props) => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const { state } = useLocation();
  const { logIn, loggedIn } = useAdmin();
  const { hide: hideAppBar } = useAppBar();
  const [loading, setLoading] = useState(true);
  const { show: showMessage } = useSnackbar();

  useEffect(() => {
    hideAppBar();

    return auth.onAuthStateChanged(() => {
      setLoading(false);
      if (state?.error) {
        showMessage(state?.error, "error");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: FieldValues) => {
    setLoading(true);
    const login = () => {
      logIn(data.username, data.password)
        .then(() => {
          navigate("/admin");
        })
        .catch((error) => showMessage(error, "error"))
        .finally(() => {
          setLoading(false);
        });
    };

    if (!auth.currentUser) {
      signInAnonymously(auth).then(() => {
        login();
      });
    } else {
      login();
    }
  };

  if (loggedIn) {
    return <Navigate to="/admin" />;
  }

  return loading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          textAlign: "center",
          width: 350,
          p: theme.spacing(2),
          pb: theme.spacing(0),
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Typography component="h1" variant="h5">
          Administrationsbereich
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={(theme) => ({ mt: 1, padding: theme.spacing(2) })}
        >
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                required
                label="Name"
                type="text"
                fullWidth
              />
            )}
            control={control}
            name="username"
            defaultValue={""}
          />
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                required
                label="Passwort"
                type="password"
                autoComplete="current-password"
                fullWidth
                sx={{ mt: 2 }}
              />
            )}
            control={control}
            name="password"
            defaultValue={""}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Anmelden
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdminLogin;
