import { createContext, Dispatch, SetStateAction } from "react";
import { Invitations } from "../../types/userTypes";

export interface Credentials {
  username: string;
  password: string;
}

export interface AdminAuthenticationState {
  authenticatedUser?: {
    [uuid: string]: Credentials;
  };
  authenticatedAt?: Date;
  invitations?: Invitations;
}

export type AdminAuthenticationDispatch = {
  setAuthenticationState?: Dispatch<
    SetStateAction<AdminAuthentication | undefined>
  >;
};

export type AdminAuthentication = AdminAuthenticationState &
  AdminAuthenticationDispatch;

export const AdminAuthenticationStateContext = createContext<
  AdminAuthentication | undefined
>(undefined);
