import { Grid, Paper } from "@mui/material";
import { useEffect } from "react";
import { GuestList } from "../components/GuestList";
import Logo from "../components/Logo";
import { useAdmin } from "../hooks/useAdmin";
import { useAppBar } from "../hooks/useAppBar";

interface Props {}

const AdminArea = ({}: Props) => {
  const { invitations } = useAdmin();
  const { show, setTitle } = useAppBar();

  useEffect(() => {
    show();
    setTitle("Administrationsbereich");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          justifyContent: "center",
        })}
      >
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <Logo />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={(theme) => ({
          justifyContent: "center",
          marginBottom: theme.spacing(10),
        })}
      >
        <Grid item xs={12} sm={12} lg={8} xl={6}>
          <Paper>
            {invitations ? (
              <GuestList
                rows={Object.entries(invitations).map(
                  ([invitationId, invitation]) => ({
                    invitationId,
                    guests: invitation.guests,
                  })
                )}
              />
            ) : undefined}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminArea;
