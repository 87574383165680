import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Guest } from "../../types/userTypes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import "./Row.css";
import { StyledTableCell, StyledTableRow } from "./InvitationList";

interface Props {
  row: {
    invitationId: string;
    guests: Guest[];
  };
}

export const Row = ({ row }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const numberOfGuests = row.guests.length;
  const countParticipants = row.guests.reduce((accumulator, guest) => {
    return accumulator + (guest.participate ? 1 : 0);
  }, 0);
  const countVegetarian = row.guests.reduce((accumulator, guest) => {
    return accumulator + (guest.mealType === "VEGETARIAN" ? 1 : 0);
  }, 0);

  const guests = row.guests;
  guests.sort((left, right) => {
    return left.ordinal - right.ordinal;
  });

  return (
    <>
      <StyledTableRow className="no-bottom-border">
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{row.invitationId}</StyledTableCell>
        <StyledTableCell align="center">{countVegetarian}</StyledTableCell>
        <StyledTableCell align="center">{countParticipants}</StyledTableCell>
        <StyledTableCell align="center">{numberOfGuests}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={5}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Zugeordnete Gäste
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Vegetarisch</TableCell>
                    <TableCell align="center">
                      Inklusive Fisch & Fleisch
                    </TableCell>
                    <TableCell align="center">Teilnahme</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {guests.map((guestRow) => (
                    <TableRow key={guestRow.userId}>
                      <TableCell>{`${guestRow.firstName} ${guestRow.lastName}`}</TableCell>
                      <TableCell align="center">
                        {guestRow.mealType === "VEGETARIAN" ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {guestRow.mealType === "MEAT" ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {guestRow.participate ? <CheckIcon /> : <CloseIcon />}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
