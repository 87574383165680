import { Grow, GrowProps, Snackbar } from "@mui/material";
import { forwardRef, ReactNode, useCallback, useState } from "react";
import { Severity, SnackbarContext } from "./context";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface Props {
  children: ReactNode;
}

interface State {
  open: boolean;
  message?: string;
  severity?: Severity;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// eslint-disable-next-line no-empty-pattern
export const SnackbarProvider = ({ children }: Props) => {
  const [state, setState] = useState<State | undefined>({
    open: false,
  });

  const show = useCallback(
    (message: string, severity: Severity) => {
      setState({
        open: true,
        message,
        severity,
      });
    },
    [setState]
  );

  const handleClose = useCallback(() => {
    setState({
      ...state,
      open: false,
    });
  }, [state, setState]);

  return (
    <SnackbarContext.Provider
      value={{
        show,
      }}
    >
      {children}
      <Snackbar
        open={state?.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grow in={state?.open}>
          <Alert
            severity={state?.severity}
            onClose={handleClose}
            sx={{ width: "100%" }}
          >
            {state?.message}
          </Alert>
        </Grow>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
