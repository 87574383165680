import { createContext } from "react";

export interface AppBarState {
  isVisible: boolean;
  title?: string;
}

export interface AppBarActions {
  show: () => void;
  hide: () => void;
  setTitle: (title: string) => void;
}

export type AppBarContext = AppBarState & AppBarActions

export const AppBarStateContext = createContext<AppBarContext | undefined>(
  undefined
);
