export type MealType = "MEAT" | "VEGETARIAN";

export const isMealType = (key: string): key is MealType => {
  return ["MEAT", "VEGETARIAN"].includes(key);
};

export interface User {
  firstName: string;
  lastName: string;
  mealType: MealType;
  ordinal: number;
  participate: boolean;
}

export interface Users {
  [invitationId: string]: {
    [userUUID: string]: User;
  };
}

export type Guest = {
  userId: string;
} & User;

export type Invitations = {
  [invitationId: string]: {
    guests: Guest[]
  }
}
