import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode, useCallback, useState } from "react";
import { AppBarContext, AppBarStateContext } from "./context";
import MenuIcon from "@mui/icons-material/Menu";
import { useAdmin } from "../../hooks/useAdmin";

interface Props {
  children: ReactNode;
}

export const AppBarProvider = ({ children }: Props) => {
  const [state, setState] = useState<AppBarContext | undefined>(undefined);
  const { logOut, authenticatedUser } = useAdmin();

  const show = useCallback(() => {
    setState((previousState) => ({
      isVisible: true,
      title: previousState?.title,
      show: previousState?.show ? previousState.show : () => {},
      hide: previousState?.hide ? previousState.hide : () => {},
      setTitle: previousState?.setTitle ? previousState.setTitle : () => {},
    }));
  }, [setState]);

  const hide = useCallback(() => {
    setState((previousState) => ({
      isVisible: false,
      title: previousState?.title,
      show: previousState?.show ? previousState.show : () => {},
      hide: previousState?.hide ? previousState.hide : () => {},
      setTitle: previousState?.setTitle ? previousState.setTitle : () => {},
    }));
  }, [setState]);

  const setTitle = useCallback(
    (title: string) => {
      setState((previousState) => ({
        isVisible: previousState?.isVisible ?? false,
        title,
        show: previousState?.show ? previousState.show : () => {},
        hide: previousState?.hide ? previousState.hide : () => {},
        setTitle: previousState?.setTitle ? previousState.setTitle : () => {},
      }));
    },
    [setState]
  );

  return (
    <AppBarStateContext.Provider
      value={{
        isVisible: state?.isVisible ?? false,
        title: state?.title,
        show,
        hide,
        setTitle,
      }}
    >
      {state?.isVisible ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed">
            <Toolbar
              sx={(theme) => ({
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
              })}
              variant="dense"
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {state.title}
              </Typography>
              <Button color="inherit" onClick={logOut}>
                {authenticatedUser
                  ? `${Object.values(authenticatedUser)[0].username} `
                  : ""}
                Abmelden
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
      ) : undefined}
      {state?.isVisible ? (
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(10),
          })}
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </AppBarStateContext.Provider>
  );
};
